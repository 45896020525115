exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adm-test-pages-js": () => import("./../../../src/pages/adm/test-pages.js" /* webpackChunkName: "component---src-pages-adm-test-pages-js" */),
  "component---src-pages-control-productos-bazar-js": () => import("./../../../src/pages/control/productos-bazar.js" /* webpackChunkName: "component---src-pages-control-productos-bazar-js" */),
  "component---src-pages-control-productos-bazar-old-js": () => import("./../../../src/pages/control/productos-bazar-old.js" /* webpackChunkName: "component---src-pages-control-productos-bazar-old-js" */),
  "component---src-pages-control-productos-odoo-extra-js": () => import("./../../../src/pages/control/productos-odoo-extra.js" /* webpackChunkName: "component---src-pages-control-productos-odoo-extra-js" */),
  "component---src-pages-dev-ssr-page-js": () => import("./../../../src/pages/dev/ssr-page.js" /* webpackChunkName: "component---src-pages-dev-ssr-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marcas-mezcal-js": () => import("./../../../src/pages/marcas-mezcal.js" /* webpackChunkName: "component---src-pages-marcas-mezcal-js" */),
  "component---src-pages-mezcales-js": () => import("./../../../src/pages/mezcales.js" /* webpackChunkName: "component---src-pages-mezcales-js" */),
  "component---src-pages-mezcales-test-js": () => import("./../../../src/pages/mezcales-test.js" /* webpackChunkName: "component---src-pages-mezcales-test-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-portafolio-js": () => import("./../../../src/pages/portafolio.js" /* webpackChunkName: "component---src-pages-portafolio-js" */),
  "component---src-pages-preview-[codigo]-js": () => import("./../../../src/pages/preview/[codigo].js" /* webpackChunkName: "component---src-pages-preview-[codigo]-js" */),
  "component---src-pages-preview-prod-id-edit-js": () => import("./../../../src/pages/preview/[prod_id]/edit.js" /* webpackChunkName: "component---src-pages-preview-prod-id-edit-js" */),
  "component---src-pages-rancho-admon-bitacora-js": () => import("./../../../src/pages/rancho/admon-bitacora.js" /* webpackChunkName: "component---src-pages-rancho-admon-bitacora-js" */),
  "component---src-pages-rancho-admon-ganado-js": () => import("./../../../src/pages/rancho/admon-ganado.js" /* webpackChunkName: "component---src-pages-rancho-admon-ganado-js" */),
  "component---src-pages-rancho-bit-registro-id-js": () => import("./../../../src/pages/rancho/bit/[registro_id].js" /* webpackChunkName: "component---src-pages-rancho-bit-registro-id-js" */),
  "component---src-pages-rancho-bitacora-js": () => import("./../../../src/pages/rancho/bitacora.js" /* webpackChunkName: "component---src-pages-rancho-bitacora-js" */),
  "component---src-pages-rancho-editar-ganado-ganado-id-js": () => import("./../../../src/pages/rancho/editar-ganado/[ganado_id].js" /* webpackChunkName: "component---src-pages-rancho-editar-ganado-ganado-id-js" */),
  "component---src-pages-rancho-editar-inv-registro-id-js": () => import("./../../../src/pages/rancho/editar-inv/[registro_id].js" /* webpackChunkName: "component---src-pages-rancho-editar-inv-registro-id-js" */),
  "component---src-pages-rancho-ganadodb-js": () => import("./../../../src/pages/rancho/ganadodb.js" /* webpackChunkName: "component---src-pages-rancho-ganadodb-js" */),
  "component---src-pages-rancho-indi-ganado-id-js": () => import("./../../../src/pages/rancho/indi/[ganado_id].js" /* webpackChunkName: "component---src-pages-rancho-indi-ganado-id-js" */),
  "component---src-pages-rancho-inv-registro-id-js": () => import("./../../../src/pages/rancho/inv/[registro_id].js" /* webpackChunkName: "component---src-pages-rancho-inv-registro-id-js" */),
  "component---src-pages-rancho-inventarios-ganado-js": () => import("./../../../src/pages/rancho/inventarios-ganado.js" /* webpackChunkName: "component---src-pages-rancho-inventarios-ganado-js" */),
  "component---src-pages-rancho-pend-registro-id-js": () => import("./../../../src/pages/rancho/pend/[registro_id].js" /* webpackChunkName: "component---src-pages-rancho-pend-registro-id-js" */),
  "component---src-pages-rancho-pendientes-js": () => import("./../../../src/pages/rancho/pendientes.js" /* webpackChunkName: "component---src-pages-rancho-pendientes-js" */),
  "component---src-pages-rancho-proy-registro-id-js": () => import("./../../../src/pages/rancho/proy/[registro_id].js" /* webpackChunkName: "component---src-pages-rancho-proy-registro-id-js" */),
  "component---src-pages-rancho-proyectos-js": () => import("./../../../src/pages/rancho/proyectos.js" /* webpackChunkName: "component---src-pages-rancho-proyectos-js" */),
  "component---src-pages-rancho-ubi-registro-id-js": () => import("./../../../src/pages/rancho/ubi/[registro_id].js" /* webpackChunkName: "component---src-pages-rancho-ubi-registro-id-js" */),
  "component---src-pages-rancho-ubicaciones-js": () => import("./../../../src/pages/rancho/ubicaciones.js" /* webpackChunkName: "component---src-pages-rancho-ubicaciones-js" */),
  "component---src-pages-sitios-mezcal-js": () => import("./../../../src/pages/sitios-mezcal.js" /* webpackChunkName: "component---src-pages-sitios-mezcal-js" */),
  "component---src-pages-todoherramientas-reviews-js": () => import("./../../../src/pages/todoherramientas/reviews.js" /* webpackChunkName: "component---src-pages-todoherramientas-reviews-js" */)
}

